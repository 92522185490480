<template>
<div class="modal-mask"> 
      <div
      v-on-clickaway="closeHelpPanel"
      class="flex-col h-[90vh] w-[60rem] flex z-10 rounded-md shadow-md absolute bg-white dark:bg-slate-800"
    >
      <div
        class="flex-row items-center border-b border-solid pt-5 pb-3 px-6 border-slate-50 dark:border-slate-700 w-full flex"
      >
      <div class="items-center flex w-full">
          <span class="text-xl font-bold text-slate-800 dark:text-slate-100">
            {{ $t('Ajuda') }}
          </span>
        </div>
      <div class="flex w-full justify-end">
          <woot-button
            color-scheme="secondary"
            variant="link"
            size="medium"
            icon="dismiss"
            @click="closeHelpPanel"
          />
        </div> 
      </div>
      <OnboardingView @close-modal-button="closeHelpPanel"/>
    </div>  
</div>
</template>

<script>
import Modal from '../../Modal.vue';
import OnboardingView from '../../widgets/conversation/OnboardingView.vue'
export default {
  data() {
    return {
      show: true,
    };
  },
  components: {
    Modal,
    OnboardingView
  },
  methods: {
    teste() {
      this.show = true;
    },
    cancel() {
    },
    closeHelpPanel() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
