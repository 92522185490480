<template>
    <button
      class="button-new flex items-center justify-center border-green-600 dark:border-white text-green-600 dark:text-white text-sm font-medium cursor-pointer"
      @click="handleClick"
    >
      <fluent-icon
        v-if="icon"
        :size="18"
        :icon="icon"
        class="mr-2"
      />
      <slot></slot>
    </button>
  </template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';

export default {
  name: 'GreenButton',
  components: { EmojiOrIcon },
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.button-new {
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 0.775px solid;
  font-family: Poppins;
  background: none;
}

</style>
