import { render, staticRenderFns } from "./SearchResultConversationItem.vue?vue&type=template&id=3c4b35b4&scoped=true&"
import script from "./SearchResultConversationItem.vue?vue&type=script&lang=js&"
export * from "./SearchResultConversationItem.vue?vue&type=script&lang=js&"
import style0 from "./SearchResultConversationItem.vue?vue&type=style&index=0&id=3c4b35b4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4b35b4",
  null
  
)

export default component.exports