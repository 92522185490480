<template>
  <div class="w-[403px] bg-slate-900">
    <div class="flex justify-between">
      <button
        :class="{
          'border-b-4 border-transparent': selectedButton !== 'todos',
          'border-b-4 border-green-500': selectedButton === 'todos',
          'text-green-500': selectedButton === 'todos',
          'relative': true
        }"
        @click="selectButton('todos')"
        class="px-4 py-2 flex items-center"
      >
        Todos
        <span :class="{'bg-green-500': selectedButton === 'todos', 'bg-transparent': selectedButton !== 'todos'}" class="absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs">
          21
        </span>
      </button>
      <button
        :class="{
          'border-b-4 border-transparent': selectedButton !== 'naoLidas',
          'border-b-4 border-green-500': selectedButton === 'naoLidas',
          'text-green-500': selectedButton === 'naoLidas',
          'relative': true
        }"
        @click="selectButton('naoLidas')"
        class="px-4 py-2 flex items-center"
      >
        Não Lidas
        <span :class="{'bg-green-500': selectedButton === 'naoLidas', 'bg-transparent': selectedButton !== 'naoLidas'}" class="absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs">
          21
        </span>
      </button>
      <button
        :class="{
          'border-b-4 border-transparent': selectedButton !== 'lidas',
          'border-b-4 border-green-500': selectedButton === 'lidas',
          'text-green-500': selectedButton === 'lidas',
          'relative': true
        }"
        @click="selectButton('lidas')"
        class="px-4 py-2 flex items-center"
      >
        Lidas
        <span :class="{'bg-green-500': selectedButton === 'lidas', 'bg-transparent': selectedButton !== 'lidas'}" class="absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs">
          21
        </span>
      </button>
    </div>
    <div :class="{'border-b border-gray-300': selectedButton !== null}"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedButton: null
    };
  },
  methods: {
    selectButton(button) {
      this.selectedButton = button;
    }
  }
};
</script>

<style scoped>

</style>
