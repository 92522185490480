import { mapGetters } from 'vuex';
import { formatTime } from '@chatwoot/utils';

export default {
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
    }),
  },
  methods: {
    calculateTrend(key) {
      if (!this.accountSummary.previous[key]) return 0;
      const diff = this.accountSummary[key] - this.accountSummary.previous[key];
      return Math.round((diff / this.accountSummary.previous[key]) * 100);
    },
    displayMetric(key) {
      if (this.isAverageMetricType(key)) {
        console.log(this.accountSummary[key])
        console.log(this.formatTimePtbr(this.accountSummary[key]))
        return this.formatTimePtbr(this.accountSummary[key]);
      }
      return Number(this.accountSummary[key] || '').toLocaleString();
    },
    displayInfoText(key) {
      if (this.metrics[this.currentSelection].KEY !== key) {
        return '';
      }
      if (this.isAverageMetricType(key)) {
        const total = this.accountReport.data
          .map(item => item.count)
          .reduce((prev, curr) => prev + curr, 0);
        return `${this.metrics[this.currentSelection].INFO_TEXT} ${total}`;
      }
      return '';
    },
    isAverageMetricType(key) {
      return [
        'avg_first_response_time',
        'avg_resolution_time',
        'reply_time',
      ].includes(key);
    },
    formatTimePtbr  (timeInSeconds){
      const secondsInMinute = 60;
      const secondsInHour = secondsInMinute * 60;
      const secondsInDay = secondsInHour * 24;
  
      const days = Math.floor(timeInSeconds / secondsInDay);
      const hours = Math.floor((timeInSeconds % secondsInDay) / secondsInHour);
      const minutes = Math.floor((timeInSeconds % secondsInHour) / secondsInMinute);
      const seconds = timeInSeconds % secondsInMinute;
  
      const formattedParts = [];
  
      if (days > 0) {
          formattedParts.push(`${days} Dia${days !== 1 ? 's' : ''}`);
      }
  
      if (hours > 0) {
          formattedParts.push(`${hours} Horas`);
      }
  
      if (minutes > 0 && formattedParts.length < 2) {
          formattedParts.push(`${minutes} Minutos`);
      }
  
    
      if (seconds > 0 && formattedParts.length < 2) {
          formattedParts.push(`${seconds} Segundos`);
      }

      return formattedParts.slice(0, 2).join(' ')
    }
  },
};
