<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-modal size="medium" :show.sync="showAddPopup" :on-close="hideAddPopup">
      <history-sync @close="hideAddPopup" :atoken="this.atoken" :inbox="this.inbox" :user="this.user"/>
    </woot-modal>
    
    
    <div class="loading-container" v-if="loading">
      <div class="spinner-qrcode"></div>
      <p>Carregando...</p>
    </div> 
    <settings-section
      v-if="!counted && !loading"
      :title="$t('Whatsapp QRCode')"
      :sub-title="$t('Conecte seu Whatsapp via QR Code.')"
    >
      <woot-button
        type="button"
        @click="getQrcode"
        variant="smooth"
        color-scheme="success" 
        :disabled="info" 
      >
        {{ $t('Conectar') }}  
      </woot-button>


      <woot-button
        type="button"
        :disabled="!info && !counted && !loading"
        variant="smooth"
        color-scheme="alert"
        class="button--disconnect"
        @click="disconnectQrcode"
      >
        {{ $t('Desconectar') }}
      </woot-button>
    </settings-section>
    <settings-section
      v-if="info && !loading"
      :title="$t('Comandos da Conexão')"
      :sub-title="$t('Comandos avançados da conexão.')"
    >
      <!--<woot-button
        type="button"
        :disabled="!info && !counted && !loading"
        variant="smooth"
        color-scheme="warning"
        class="button--disconnect"
        @click="commandRestar"
      >
        {{ $t('Reiniciar') }}
      </woot-button>
      <woot-button
        type="button"
        :disabled="!info && !counted && !loading"
        variant="smooth"
        color-scheme="warning"
        class="button--disconnect"
        @click="postWebhook"
      >
        {{ $t('Recriar Webhook') }}
      </woot-button>
      <woot-button
        type="button"
        :disabled="!info && !counted && !loading"
        variant="smooth"
        color-scheme="warning"
        class="button--disconnect"
        @click="updateInbox"
      >
        {{ $t('Recriar Inbox URL') }}
      </woot-button>
      -->
      <woot-button
        type="button"
        :disabled="!info && !counted && !loading"
        variant="smooth"
        color-scheme="success"
        class="button--disconnect"
        @click="openAddPopup()"
      >
        {{ $t('Sincronizar conversas') }}
      </woot-button>
    </settings-section>



    <settings-section
      v-if="info && !counted && !loading"
      :title="$t('Configurações')"
      :sub-title="$t('Configurações de personalização da integração.')"
    >
      <input
        type="checkbox"
        value="Quepasa_pat"
        @change="setPAT()"
        v-model="pat"
        :checked="!pat"
      />
      <label for="Quepasa_pat">
        {{ $t('Não enviar nome do atendente nas respostas.') }}
      </label>

      <br />

      <input
        type="checkbox"
        value="Quepasa_enableGroups"
        @click="command('groups')"
        :checked="groups"
      />
      <label for="Quepasa_enableGroups">
        {{ $t('Habilitar Grupos') }}
      </label>

      <br />

      <input
        type="checkbox"
        value="conversaUnica"
        @change="setConversaUnica()"
        :checked="this.inbox.lock_to_single_conversation"
        ref="conversaUnica"
      />
      <label for="conversaUnica">
        {{ $t('Conversa Unica') }}
      </label>
    </settings-section>

    <settings-section
      v-if="!loading"
      :title="$t('Informações')"
      :sub-title="$t('Informações da integração com o Whatsapp')"
      :show-border="false"
    >
      <label class="w-[100%] pb-4" v-if="info">
        {{ $t('Status da Conexão:') }}
        <p>✅ Conectado</p>
        <p>📱 {{ wid }}</p>
      </label>
      <label class="w-[65%] pb-4" v-if="!info && !counted">
        {{ $t('Status da Conexão:') }}
        <p>❌ Desconectado</p>
      </label>
<!--
      <label class="w-[65%] pb-4" v-if="info">
        {{ $t('Inbox Identifier & Quepasa Token') }}
        <woot-code :script="inbox.inbox_identifier" />
      </label>

      <label class="w-[65%] pb-4" v-if="info">
        {{ $t('Json:') }}
        <code class="hljs javascript">{{ extra }}</code>
      </label>-->
    </settings-section>

    <div class="container" v-if="img && counted && !loading">
      <div class="flex flex-row">
        <img :src="img" alt="img" class="mr-5 rounded-md" />
        <div class="container-subtitle">
          <p class="text-base font-medium">
            {{
              $t(
                'Observação: lembre-se que o QrCode terá validade apenas de 20 segundos, esteja com o celular em mãos para digitalizar o código.'
              )
            }}
          </p>
          <p class="text-base font-medium mt-10">
            {{ sec }} segundos restantes
          </p>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex flex-row">
          <div class="h-4 w-4 emoji-suc mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M13.0353 0.857178L15.6334 3.45534L6.0535 13.0476L0.857178 7.84512L3.45534 5.24696L6.0535 7.84512L13.0353 0.857178ZM13.0353 2.58108L6.0535 9.57518L3.45534 7.0078L2.58723 7.84512L6.0535 11.3052L13.9034 3.45534L13.0353 2.58108Z"
                fill="#F7F6F4"
              />
            </svg>
          </div>
          <p>
            {{ $t('QrCode gerado com sucesso!') }}
          </p>
        </div>
        <div class="flex flex-row mt-5">
          <div class="h-4 w-4 emoji-suc mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M13.0353 0.857178L15.6334 3.45534L6.0535 13.0476L0.857178 7.84512L3.45534 5.24696L6.0535 7.84512L13.0353 0.857178ZM13.0353 2.58108L6.0535 9.57518L3.45534 7.0078L2.58723 7.84512L6.0535 11.3052L13.9034 3.45534L13.0353 2.58108Z"
                fill="#F7F6F4"
              />
            </svg>
          </div>
          <p>
            {{ $t('Digitalize este código QR  nos próximos 20 segundos.') }}
          </p>
        </div>
      </div>

    </div>

  </div>

</template>
  










  <script>
import HistorySync from './HistorySync.vue';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import BusinessDay from './BusinessDay.vue';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import { mapGetters } from 'vuex';
import { actions } from '../../../../../store/modules/draftMessages';

export default {
  components: {
    SettingsSection,
    BusinessDay,
    HistorySync,
    WootMessageEditor,
  },
  mixins: [alertMixin, actions],
  data() {
    return {
      showAddPopup: false,
      img: '',
      info: false,
      token: '',
      wid: '',
      verified: '',
      infoString: '',
      sec: 20,
      counted: false,
      interval: null,
      loading: false,
      atoken: '',
      urlWeb: '',
      user: '',
      extra: '',
      groups: false,
      pat: '',
      sync: false,
      quepasaUrl: process.env.WOOTIFY_QP_PUBLIC_URL, 
      quepasaUser: process.env.WOOTIFY_QP_USER, 
      cwhost: process.env.WOOTIFY_CW_HOST, 
      cwpublic: process.env.WOOTIFY_CW_PUBLIC_URL, 
      n8nhost: process.env.WOOTIFY_N8N_HOST,
      qphost: process.env.WOOTIFY_QP_HOST, 
      agentBotName: process.env.WOOTIFY_CW_AGENTBOT,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      agentBots: 'agentBots/getBots',
    }),
    activeAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](this.inbox.id);
    },
    listBots() {},
  },
  watch: {
    async activeAgentBot() {
      this.agentList = await this.agentBots;
      this.agentList = await this.agentBots;
      const agent = this.activeAgentBot;
      this.atoken = agent.access_token;

      if (!agent.id) {
        this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: this.inbox.id,
          botId: this.agentList[0].id,
        });
      }

      if (this.atoken && !this.inbox.webhook_url) {
        this.getUrl();
      }
    },
  }, 
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false; 
    },
    setPAT() {
      console.log('pat: ' + this.pat);
      this.getUrl();
      this.getInfo();
    },
    async setConversaUnica() {
      console.log('inbox.lock_to_single_conversation = ' + this.inbox.lock_to_single_conversation);

      this.getUrl();

      this.postWebhook(); 

    },
    async getInfo(patch = false) {
      
      console.log('getInfo');
      this.loading = true;
      const apiUrlInfo = this.quepasaUrl + '/info';
      const inboxId = this.inbox.inbox_identifier;

      try {
        const response = await axios.get(apiUrlInfo, {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': inboxId,
          },
        });
        //console.log(response);
        if (response.status == 204) {
          this.info = false;
        } else {
          if (response.data == '') {
            this.info = false;
          } else {
            this.info = response.data.server.verified;
            this.token = response.data.server.token;
            this.wid = this.formarter(response.data.server.wid);
            this.extra = response.data.server;
            this.groups = response.data.server.groups;
          }
        }
      } catch (error) {
        console.error(error);
      }

      if (this.info && patch) {
        this.getInfoPatch();
      }

      this.loading = false;
    },
    async getInfoPatch() {
      console.log('getInfoPatch');
      this.loading = true;
      const apiUrl = this.quepasaUrl + '/info';
      const inboxId = this.inbox.inbox_identifier;

      try {
        const response = await axios.patch(
          apiUrl,
          { username: this.quepasaUser },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-QUEPASA-TOKEN': inboxId,
            },
          }
        );
        this.info = response.data.server.verified;
        this.token = response.data.server.token;
        this.wid = this.formarter(response.data.server.wid);
        if (this.info) {
          await this.postWebhook();
          await this.commandRestar();
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getQrcode() {
      console.log('getQrcode');
      this.info = false;
      this.loading = true;
      const apiUrl = this.quepasaUrl + '/scan';
      const inboxId = this.inbox.inbox_identifier;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': inboxId,
          },
          responseType: 'arraybuffer',
        });

        if (response.data instanceof ArrayBuffer) {
          let base64String = btoa(
            String.fromCharCode.apply(null, new Uint8Array(response.data))
          );

          this.img = 'data:image/png;base64,' + base64String;
        }
      } catch (error) {
        console.error(error);
      }
      this.start();
      this.loading = false;
    },
    async disconnectQrcode() {
      //this.command('stop');
      let response = await axios.delete(process.env.WOOTIFY_QP_PUBLIC_URL + "/info",
        {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': this.inbox.inbox_identifier, 
        }, 
      });
      console.log(response);
      if(response.data.success === true){
        this.showAlert('Desconectado');
        this.getInfo();
      }
        
    },
    async historySync() {
      this.showAlert('Iniciando Sincronismo de conversas...');
      console.log('historySync');
      this.sync = true;
      this.info = false;
 

      this.sync = false;
      this.info = true;
    },
    async updateInbox(singleConversation = false) {
      console.log('updateInbox');
      console.log('Conversa Unica: ' + this.$refs.conversaUnica.checked);

      const payload = {
        id: this.inbox.id,
        lock_to_single_conversation: this.$refs.conversaUnica.checked,
        channel: {
          webhook_url: this.urlWeb,
        },
      };



      await this.$store.dispatch('inboxes/updateInbox', payload);
      this.$emit('update-webhook', this.urlWeb);
    },
    start() {
      this.counted = true;
      this.sec = 20;
      this.interval = setInterval(() => {
        this.sec--;

        if (this.sec < 0) {
          clearInterval(this.interval);
          this.counted = false;
          this.loading = true;

          // call getInfo and true for patch if qrcode gets scanned ok
          this.getInfo(true);
        }
      }, 1000);
    },
    formarter(number) {
      var codigoPais;
      var codigoArea;
      var numeroTelefone;

      if (number.substring(12, 13) == '.') {
        codigoPais = number.substring(0, 2);

        codigoArea = number.substring(2, 4);

        numeroTelefone = number.substring(4, 12);

        return `+${codigoPais} ${codigoArea} ${numeroTelefone.slice(
          0,
          4
        )}-${numeroTelefone.slice(4)}`;
      } else {
        codigoPais = number.substring(0, 2);

        codigoArea = number.substring(2, 4);

        numeroTelefone = number.substring(4, 13);

        return `+${codigoPais} ${codigoArea} ${numeroTelefone.slice(
          0,
          5
        )}-${numeroTelefone.slice(5)}`;
      }
    },
    getUrl() {
      try {
        this.user = this.currentUser;
        this.urlWeb =
          this.n8nhost +
          '/webhook/from-chatwoot?' +
          'qphost=' +
          this.qphost +
          '&identifier=' +
          this.inbox.inbox_identifier +
          '&cwhost=' +
          this.cwhost +
          '&cwpublic=' +
          this.cwpublic +
          '&utoken=' +
          this.user.access_token +
          '&atoken=' +
          this.atoken;
        if (this.pat) this.urlWeb = this.urlWeb + '&pat=false';
        if (this.$refs.conversaUnica.checked) this.urlWeb = this.urlWeb + '&st=true';
        if (this.$refs.conversaUnica.checked) this.urlWeb = this.urlWeb + '&singleConversation=true';

        this.updateInbox();

      } catch (error) {
        console.error('Erro ao obter o agente ativo:', error);
      }
    },
    postWebhook() {
      console.log('postWebhook to quepasa');
      const apiUrl = this.quepasaUrl + '/webhook';
      const inboxId = this.inbox.inbox_identifier;

      const dto = {
        url: this.n8nhost + '/webhook/to-chatwoot',
        forwardinternal: true,
        trackid: 'chatwoot-qrcode',
        extra: {
          atoken: this.atoken,
          cwhost: this.cwhost,
          identifier: this.inbox.inbox_identifier,
          inbox: this.inbox.id,
          qphost: this.qphost,
          qptoken: this.inbox.inbox_identifier,
          account: this.accountId,
          utoken: this.user.access_token,
          singleConversation: this.$refs.conversaUnica.checked,
          st: this.$refs.conversaUnica.checked,
        },
      };

      try {
        axios.delete(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': inboxId,
          },
        });

        
      } catch (error) {
        console.error(error);
      }
      this.showAlert('Recriando Webhook no Quepasa...');
      setTimeout(() => {
        axios.post(apiUrl, dto, {
            headers: {
              'Content-Type': 'application/json',
              'X-QUEPASA-TOKEN': inboxId,
            },
        }).then( ()=> this.getInfo() );
        
        this.showAlert('Webhook Quepasa Recriado');
      }, 3000);
    },
    commandRestar() {
      console.log('commandRestar');
      const apiUrl = this.quepasaUrl + '/command';
      const inboxId = this.inbox.inbox_identifier;
      const action = 'restart';

      const urlWithParams = `${apiUrl}?action=${action}`;

      try {
        axios.get(urlWithParams, {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': inboxId,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    command(action) {
      console.log('command: ' + action);
      const apiUrl = this.quepasaUrl + '/command';
      const inboxId = this.inbox.inbox_identifier;

      const urlWithParams = `${apiUrl}?action=${action}`;

      try {
        axios.get(urlWithParams, {
          headers: {
            'Content-Type': 'application/json',
            'X-QUEPASA-TOKEN': inboxId,
          },
        });
      } catch (error) {
        console.error(error);
      }
      this.getInfo();
    },
    async postAgent() {
      console.log('postAgent');
      this.user = await this.currentUser;
      this.agentList = await this.agentBots;
      this.agentList = await this.agentBots;

      if (!this.agentList.length) {
        const apiUrl =
          this.cwpublic + '/api/v1/accounts/' + this.accountId + '/agent_bots';

        try {
          axios.post(
            apiUrl,
            { name: this.agentBotName },
            {
              headers: {
                'Content-Type': 'application/json',
                api_access_token: this.user.access_token,
              },
            }
          );
          this.activeAgentBot();
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('agentBots/get');
    await this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);

    if (this.inbox.webhook_url.includes('pat=false')) this.pat = true;
    else this.pat = false;
    
  



    this.getInfo();
    this.postAgent();

    
    //console.log(process.env.CHATWOOT_HUB_URL);

  },
  props: {
    channelQrcode: {
      type: Number,
      required: true,
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>






  




  
  <style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.button--disconnect {
  @apply ml-2;
}

.button-qrcode {
  width: 212px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 5.492px;
  border: 1.098px solid #299764;
  font-family: Poppins;
  background: none;
}

.button-qrcode-new {
  width: 300px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 5.492px;
  border: 1.098px solid #299764;
  font-family: Poppins;
  background: none;
}

.container {
  margin: 40px;
}

.container-subtitle {
  width: 480px;
}

.emoji-suc {
  border-radius: 3.448px;
  background: #38c172;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 100vh;*/
}

.spinner-qrcode {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top-color: #46e5b8;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin-qrcode 1s linear infinite;
}

@keyframes spin-qrcode {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  