import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat, { locale: ptBR });
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat, { locale: ptBR });

      if (!isSameYear(messageTime, now)) {
        return format(messageTime, "LLL d 'de' y', 'HH:mm", { locale: ptBR });
      }

      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true, locale: ptBR });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat, { locale: ptBR });
    },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/cerca de|mais de|quase|/g, '')
        .replace('menos de um minuto atrás', 'agora')
        .replace(' minuto atrás', 'm')
        .replace(' minutos atrás', 'm')
        .replace('um minuto atrás', 'm')
        .replace('uma hora atrás', 'h')
        .replace(' hora atrás', 'h')
        .replace(' horas atrás', 'h')
        .replace(' dia atrás', 'd')
        .replace('um dia atrás', 'd')
        .replace(' dias atrás', 'd')
        .replace('um mês atrás', 'm')
        .replace(' meses atrás', 'm')
        .replace(' mês atrás', 'm')
        .replace('um ano atrás', 'a')
        .replace(' ano atrás', 'a')
        .replace(' anos atrás', 'a');
      return convertToShortTime;
    },
  },
};
