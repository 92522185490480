<template>
  <div
    class="flex justify-between items-center w-full h-14 px-4 py-6 bg-white dark:bg-slate-800 border-left"
  >
    <account-selector
      :show-account-modal="showAccountModal"
      @close-account-modal="toggleAccountModal"
    />
    <h1
      class="poppins ml-12 text-lg font-medium mb-0 flex items-center text-slate-900 dark:text-slate-100"
    >
      <back-button
        v-if="showBackButton"
        :button-label="backButtonLabel"
        :back-url="backUrl"
      />
      <slot />
      <span class="text-slate-900 dark:text-slate-100">{{ headerTitle }}</span>
    </h1>
    <router-link
      v-if="showNewButton && isAdmin"
      :to="buttonRoute"
      class="button success button--fixed-top"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ buttonText }}
      </span>
    </router-link>
    <button
      class="custom-button-over w-[270px] bg-white dark:bg-slate-800"
    ></button>
    <div class="flex gap-2">
      <agent-details
        @toggle-menu="toggleOptions"
        :opened="showOptionsMenu"
        class="teste"
      />
      <options-menu
        :show="showOptionsMenu"
        :large="large"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton.vue';
import adminMixin from '../../../mixins/isAdmin';
import AgentDetails from '../../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../../components/layout/sidebarComponents/OptionsMenu.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';

export default {
  components: {
    AccountSelector,
    BackButton,
    AgentDetails,
    OptionsMenu,
  },
  methods: {
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleOptions(widthButton) {
      console.log(widthButton);
      this.large = widthButton;
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
  },
  data() {
    return {
      large: 0,
      showOptionsMenu: false,
      showAccountModal: false,
    };
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    backButtonLabel: {
      type: String,
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.poppins {
  font-family: Poppins;
}

.teste {
  position: fixed;
  top: 0.7rem;
  right: 15px;
}

.custom-button-over {
  height: 45.73px;
  border-radius: 22.865px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  padding: 0 16px;
}

.border-left {
    border-left: 2px solid #e30613;
}

.border-div {
  border-bottom: 6px solid #eee;
}
</style>
