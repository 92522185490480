<template>
  <header class="bg-white dark:bg-slate-800 border-b border-slate-50 dark:border-slate-800">
    <account-selector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
      />
    <div class="flex justify-between w-full py-6 px-4 h-14 border-left">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <h1
          class="m-0 ml-12 poppins text-lg font-medium text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2">
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex items-center gap-2">
        <div class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap">
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon icon="search" class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200" />
          </div>
          <input type="text" :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search button-rounded" :value="searchQuery" @keyup.enter="submitSearch"
            @input="inputSearch" />
          <woot-button :is-loading="false" class="clear" :class-names="searchButtonClass" @click="submitSearch">
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <agent-details @toggle-menu="toggleOptions" :opened="showOptionsMenu" />
        <options-menu
          :show="showOptionsMenu"
          :large="large"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @key-shortcut-modal="$emit('key-shortcut-modal')"
          @close="toggleOptions"
        />
      </div>
    </div>  
    <div class="border-b" style="color: #5A5A5D"></div>
    <div class="flex gap-2 justify-end">

      <GreenButton v-if="hasAppliedFilters && !hasActiveSegments" class="my-5" icon="save" @click="onToggleSegmentsModal">
        {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_SAVE') }}
      </GreenButton>
      <GreenButton class="my-5" icon="person-add" data-testid="create-new-contact" @click="toggleCreate">
        {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
      </GreenButton>

      <GreenButton v-if="isAdmin" class="my-5" icon="upload" @click="toggleImport">
        {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
      </GreenButton>

      <GreenButton class="my-5" v-if="isAdmin" icon="download" @click="submitExport">
        {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
      </GreenButton>

      <div v-if="hasActiveSegments" class="flex gap-2">
        <GreenButton class="my-5" icon="edit" @click="onToggleEditSegmentsModal">
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
        </GreenButton>
        <GreenButton class="my-5" icon="delete" @click="onToggleDeleteSegmentsModal">
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
        </GreenButton>
      </div>
      <div v-if="!hasActiveSegments" class="relative">
        <div v-if="hasAppliedFilters"
          class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full" />
        <IconButton
          class="my-5"
          color-scheme="secondary"
          data-testid="create-new-contact"
          icon="filter"
          @click="toggleFilter"
        >
        </IconButton>
      </div>

    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import GreenButton from '../../../../components/ui/GreenButton.vue'
import IconButton from '../../../../components/ui/IconButton.vue'
import AgentDetails from '../../../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../../../components/layout/sidebarComponents/OptionsMenu.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';

export default {
  components: {
    AccountSelector,
    GreenButton,
    IconButton,
    AgentDetails,
    OptionsMenu
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
      showOptionsMenu: false,
      large: 0,
      showAccountModal: false,
    };
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
  },
  methods: {
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    toggleOptions(widthButton) {
      this.large = widthButton;
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
    toggleImport() {
      this.$emit('on-toggle-import');
    },
    submitExport() {
      this.$emit('on-export-submit');
    },
    submitSearch() {
      this.$emit('on-search-submit');
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-[17.5rem] h-[2.65rem] m-0 bg-white text-black-900 placeholder:text-black-500;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible ;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }

  .button-rounded {
    border-radius: 32.887px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }


  .poppins {
    font-family: Poppins;
  }

  .border-left {
    border-left: 2px solid #e30613;
}

  
}
</style>
