<template>
  <router-link :to="searchUrl" class="bg-white flex justify-center text-slate-900 hover:text-green-500 cursor-pointer">
    <button class="flex items-center rounded-lg bg-slate-50 px-3 py-2 w-[360px] h-[38px] cursor-pointer mt-3">
      <fluent-icon icon="search" size="24" class="mr-2" />
      <span class="text-sm hover:text-green-500">Busque por conversa ou contato</span>
    </button>
  </router-link>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import timeMixin from '../../../../mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import SwitchLayout from './SwitchLayout.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
export default {
  components: {
    SwitchLayout,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [timeMixin, messageFormatterMixin, clickaway],
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    searchUrl() {
      return frontendURL(`accounts/${this.accountId}/search`);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-link {
  @apply flex flex-row text-black-900 hover:text-green-600 bg-white;
  border-radius: 22.865px;
  height: 6px;
  width: 300px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.text-link {
  margin-top: 6px;
  margin-left: 2px;
}

.search-link {
  &:hover {
    .search--icon,
    .search--label {
      @apply hover:text-green-500 dark:hover:text-green-500;
    }
  }
}
</style>
