<template>
  <div class="-mt-px text-sm">
    <button
      class="flex items-center select-none w-full bg-white dark:bg-slate-800 border border-l-0 border-r-0 border-solid m-0 border-slate-100 dark:border-slate-700/50 cursor-grab justify-between py-2 px-4 drag-handle border-div"
      @click="$emit('click')"
    >
      <div class="flex justify-between mb-0.5">
        <div>
          <img v-if="iconSrc" :src="iconSrc" alt="" class="text-slate-700 mr-3" width="16" height="24"/>
        </div>
        <h5
          class="text-slate-800 text-sm dark:text-slate-100 mb-0 py-0 pr-2 pl-0"
        >
          {{ title }}
        </h5>
      </div>
      <div class="flex flex-row">
        <slot name="button" />
        <div class="flex justify-end w-3 text-slate-900">
          <fluent-icon size="24" icon="chevron-right" type="solid" :class="{ 'rotate': isOpen }"/>
        </div>
      </div>
    </button>
    <div
      v-if="isOpen"
      class="bg-white dark:bg-slate-900"
      :class="compact ? 'p-0' : 'p-4'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';
import actions from './icons/actions.svg';
import macros from './icons/macros.svg';
import info from './icons/info.svg';
import atributos from './icons/atributos.svg';
import participantes from './icons/participantes.svg';
import convant from './icons/convant.svg'

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconSrc() {
      switch (this.title) {
        case 'Ações da conversa':
          return actions;
        case 'Macros':
          return macros;
        case 'Informação da conversa':
          return info;
        case 'Atributos do contato':
          return atributos;
        case 'Participantes da conversa':
          return participantes;
        case 'Conversas anteriores':
          return convant;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.rotate{
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.border-div {
  border-bottom: 4px solid #eee;
}
</style>
