<template>
  <section class="conversation-page bg-white dark:bg-slate-800 flex flex-col">
    <account-selector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
      />
    <div
      class="flex justify-between items-center h-14 px-4 py-6 bg-white dark:bg-slate-800 border-left border-div"
    >
      <h1
        class="poppins ml-12 text-lg font-medium mb-0 flex items-center text-slate-900 dark:text-slate-100"
      >
        <span class="text-slate-900 dark:text-slate-100">{{ 'Conversa' }}</span>
      </h1>
      <div class="flex gap-2">
        <agent-details @toggle-menu="toggleOptions" :opened="showOptionsMenu" />
        <options-menu
          :show="showOptionsMenu"
          :large="large"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @key-shortcut-modal="$emit('key-shortcut-modal')"
          @close="toggleOptions"
        />
      </div>
    </div>
    <div class="flex flex-row h-[90%]">
      <chat-list
        :show-conversation-list="showConversationList"
        :conversation-inbox="inboxId"
        :label="label"
        :team-id="teamId"
        :conversation-type="conversationType"
        :folders-id="foldersId"
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle-conversation-layout="toggleConversationLayout"
        @conversation-load="onConversationLoad"
        class="bg-white"
      >
      <pop-over-search/>
      </chat-list>
      <conversation-box
        v-if="showMessageView"
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        :is-on-expanded-layout="isOnExpandedLayout"
        @contact-panel-toggle="onToggleContactPanel"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList.vue';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox.vue';
import PopOverSearch from './search/PopOverSearch.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import wootConstants from 'dashboard/constants/globals';
import AgentDetails from '../../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../../components/layout/sidebarComponents/OptionsMenu.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';

export default {
  components: {
    AccountSelector,
    ChatList,
    ConversationBox,
    PopOverSearch,
    AgentDetails,
    OptionsMenu,
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showSearchModal: false,
      large: 0,
      showOptionsMenu: false,
      showAccountModal: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
    }),
    showConversationList() {
      return this.isOnExpandedLayout ? !this.conversationId : true;
    },
    showMessageView() {
      return this.conversationId ? true : !this.isOnExpandedLayout;
    },
    isOnExpandedLayout() {
      const {
        LAYOUT_TYPES: { CONDENSED },
      } = wootConstants;
      const { conversation_display_type: conversationDisplayType = CONDENSED } =
        this.uiSettings;
      return conversationDisplayType !== CONDENSED;
    },
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const { is_contact_sidebar_open: isContactSidebarOpen } =
          this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
  },
  watch: {
    conversationId() {
      this.fetchConversationIfUnavailable();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
  },

  methods: {
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    toggleConversationLayout() {
      const { LAYOUT_TYPES } = wootConstants;
      const {
        conversation_display_type:
          conversationDisplayType = LAYOUT_TYPES.CONDENSED,
      } = this.uiSettings;
      const newViewType =
        conversationDisplayType === LAYOUT_TYPES.CONDENSED
          ? LAYOUT_TYPES.EXPANDED
          : LAYOUT_TYPES.CONDENSED;
      this.updateUISettings({
        conversation_display_type: newViewType,
        previously_used_conversation_display_type: newViewType,
      });
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const selectedConversation = this.findConversation();
        // If conversation doesn't exist or selected conversation is same as the active
        // conversation, don't set active conversation.
        if (
          !selectedConversation ||
          selectedConversation.id === this.currentChat.id
        ) {
          return;
        }
        const { messageId } = this.$route.query;
        this.$store
          .dispatch('setActiveChat', {
            data: selectedConversation,
            after: messageId,
          })
          .then(() => {
            bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE, { messageId });
          });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleOptions(widthButton) {
      console.log(widthButton);
      this.large = widthButton;
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.poppins {
  font-family: Poppins;
}
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}

.border-left {
    border-left: 2px solid #e30613;
}

.border-div {
  border-bottom: 6px solid #eee;
}

</style>
