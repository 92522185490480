<template>
  <div class="columns notification--page">
    <div class="flex bg-slate-50 dark:bg-slate-900">
      <chat-type-tabs
        :items="items"
        :active-tab="activeAssigneeTab"
        class="tab--chat-type bg-slate-50 dark:bg-slate-900"
        @chatTabChange="updateAssigneeTab"
      />
    </div>
    <div class="notification--content medium-12">
      <notification-table
        :notifications="records"
        :is-loading="uiFlags.isFetching"
        :is-updating="uiFlags.isUpdating"
        :on-click-notification="openConversation"
        :on-mark-all-done-click="onMarkAllDoneClick"
        :active-tab="activeAssigneeTab"
      />
      <table-footer
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        @page-change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import ChatTypeTabs from '../../../../components/widgets/ChatTypeTabs.vue';

import NotificationTable from './NotificationTable.vue';
import NotificationButton from './NotificationButton.vue';
import wootConstants from 'dashboard/constants/globals';

import { ACCOUNT_EVENTS } from '../../../../helper/AnalyticsHelper/events';
export default {
  data() {
    return {
      activeAssigneeTab: 'notsee',
      items: [],
      nlida: 0,
      lida: 0,
      todos: 0,
    };
  },
  components: {
    ChatTypeTabs,
    NotificationTable,
    TableFooter,
    NotificationButton,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      meta: 'notifications/getMeta',
      records: 'notifications/getNotifications',
      uiFlags: 'notifications/getUIFlags',
    }),
  },
  watch: {
    records(newRecords, oldRecords) {
      if (newRecords) {
        for (let i = 0; i < newRecords.length; i++) {
          if (newRecords[i].read_at) {
            this.lida++;
          } else {
            this.nlida++;
          }
        }
        this.todos = newRecords.length;
      }
      this.items[0].count = this.nlida;
      this.items[1].count = this.lida;
      this.items[2].count = this.todos;
    },
  },
  mounted() {
    this.$store.dispatch('notifications/get', { page: 1 });
    this.items = [
      {
        key: 'notsee',
        count: 0,
        name: 'Não Lidas',
      },
      {
        key: 'see',
        count: 0,
        name: 'Lidas',
      },
      {
        key: 'all',
        count: 0,
        name: 'Todos',
      },
    ];
  },
  methods: {
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('notifications/get', { page });
    },
    openConversation(notification) {
      const {
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
        primary_actor: { id: conversationId },
        notification_type: notificationType,
      } = notification;

      this.$track(ACCOUNT_EVENTS.OPEN_CONVERSATION_VIA_NOTIFICATION, {
        notificationType,
      });
      this.$store.dispatch('notifications/read', {
        primaryActorId,
        primaryActorType,
        unreadCount: this.meta.unreadCount,
      });

      this.$router.push(
        `/app/accounts/${this.accountId}/conversations/${conversationId}`
      );
    },
    onMarkAllDoneClick() {
      this.$track(ACCOUNT_EVENTS.MARK_AS_READ_NOTIFICATIONS);
      this.$store.dispatch('notifications/readAll');
    },
    updateAssigneeTab(selectedTab) {
      console.log(selectedTab);
      if (this.activeAssigneeTab !== selectedTab) {
        this.activeAssigneeTab = selectedTab;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab--chat-type {
  @apply py-0 px-12;

  ::v-deep {
    .tabs {
      @apply p-0;
    }
  }
}
.notification--page {
  background: var(--white);
  overflow-y: auto;
  width: 100%;
}

.notification--content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
