import { render, staticRenderFns } from "./GreenButton.vue?vue&type=template&id=dfaf5ae6&scoped=true&"
import script from "./GreenButton.vue?vue&type=script&lang=js&"
export * from "./GreenButton.vue?vue&type=script&lang=js&"
import style0 from "./GreenButton.vue?vue&type=style&index=0&id=dfaf5ae6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfaf5ae6",
  null
  
)

export default component.exports