var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-[403px] bg-slate-900"},[_c('div',{staticClass:"flex justify-between"},[_c('button',{staticClass:"px-4 py-2 flex items-center",class:{
        'border-b-4 border-transparent': _vm.selectedButton !== 'todos',
        'border-b-4 border-green-500': _vm.selectedButton === 'todos',
        'text-green-500': _vm.selectedButton === 'todos',
        'relative': true
      },on:{"click":function($event){return _vm.selectButton('todos')}}},[_vm._v("\n      Todos\n      "),_c('span',{staticClass:"absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs",class:{'bg-green-500': _vm.selectedButton === 'todos', 'bg-transparent': _vm.selectedButton !== 'todos'}},[_vm._v("\n        21\n      ")])]),_vm._v(" "),_c('button',{staticClass:"px-4 py-2 flex items-center",class:{
        'border-b-4 border-transparent': _vm.selectedButton !== 'naoLidas',
        'border-b-4 border-green-500': _vm.selectedButton === 'naoLidas',
        'text-green-500': _vm.selectedButton === 'naoLidas',
        'relative': true
      },on:{"click":function($event){return _vm.selectButton('naoLidas')}}},[_vm._v("\n      Não Lidas\n      "),_c('span',{staticClass:"absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs",class:{'bg-green-500': _vm.selectedButton === 'naoLidas', 'bg-transparent': _vm.selectedButton !== 'naoLidas'}},[_vm._v("\n        21\n      ")])]),_vm._v(" "),_c('button',{staticClass:"px-4 py-2 flex items-center",class:{
        'border-b-4 border-transparent': _vm.selectedButton !== 'lidas',
        'border-b-4 border-green-500': _vm.selectedButton === 'lidas',
        'text-green-500': _vm.selectedButton === 'lidas',
        'relative': true
      },on:{"click":function($event){return _vm.selectButton('lidas')}}},[_vm._v("\n      Lidas\n      "),_c('span',{staticClass:"absolute right-0 top-0 h-5 w-5 flex items-center justify-center rounded-full text-xs",class:{'bg-green-500': _vm.selectedButton === 'lidas', 'bg-transparent': _vm.selectedButton !== 'lidas'}},[_vm._v("\n        21\n      ")])])]),_vm._v(" "),_c('div',{class:{'border-b border-gray-300': _vm.selectedButton !== null}})])
}
var staticRenderFns = []

export { render, staticRenderFns }