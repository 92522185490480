<template>    
<button ref="buttonUser" class="custom-button w-[215px] relative z-50 text-black-900 text-sm" @click="handleClick">
  <div class="flex items-center">
    <thumbnail
      class="mr-2"
      :src="currentUser.avatar_url"
      :username="currentUser.name"
      :status="statusOfAgent"
      should-show-status-always
      size="34px"
    />
    <span class="flex-1 truncate w-[120px]">{{ $t("Saman&co V4") }}</span>
    <fluent-icon class="ml-2" size="14" icon="chevron-right" :class="{ 'rotate-90': opened }" />
  </div>
</button>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail.vue';

export default {
  data() {
    return {
      rotate: false,
    };
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Thumbnail,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      console.log(this.currentUser)
      return this.currentUserAvailability || 'offline';
    },
  },
  methods: {
    handleClick() {
      const widthButton = this.$refs.buttonUser.offsetWidth;
      this.rotate = !this.rotate
      this.$emit('toggle-menu', widthButton);
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.custom-button {
  height: 40px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  padding: 0 16px;
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

</style>
