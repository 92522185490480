<template>  
  <woot-modal :show.sync="show" :on-close="onClose">

    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header 
        :header-title="$t('Sincronizar Historico')"
        :header-content="$t('Sincronize até 1 ano de histórico WhatsApp.')"
      />
      <section v-if="section_start">
        <div class="flex flex-col modal-content">
          <div class="w-full">

            <div class="loading-container" v-if="loading">
              <div class="spinner-qrcode"></div>
              <p>Carregando...</p>
            </div> 
            <img :src="qrcode" alt="img" class="mr-5 rounded-md" v-if="qrcode && section_start"/>         
            
            <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full" v-if="!counting || !loading">
                <woot-button class="button clear" @click.prevent="onClose" v-if="!counting || !loading">
                  {{ $t('Cancelar') }}
                </woot-button>
                <woot-button @click="sync_start" v-if="!counting || !loading">
                  {{ $t('Iniciar') }} 
                </woot-button> 
            </div>
          </div>
        </div>
      </section>

      <section v-if="section_read || section_download">
        <div class="flex flex-col modal-content">
          <div class="w-full">
                  
          </div>
          <div class="w3-container">
            <p>Mantenha o app Whatsapp aberto enquanto é feito o download.</p>
            <p v-if="section_download">Efetuando Download das mensagens...</p> 
            <p v-if="section_read">Importando mensagens...</p> 
            <br>
            <div class="w3-light-grey w3-round">
              <div class="w3-container w3-round w3-green w3-center" v-bind:style="{width: progresso + '%'}">
                {{ progresso }}%
              </div>
            </div>
            <br>
            <div v-if="progresso == 100 && section_read && section_download">
              <p>Concluido!</p> 
            </div>
            <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full" v-if="progresso == 100 && section_read && section_download">
                <woot-button class="button clear" @click.prevent="onClose">
                  {{ $t('Fechar') }}
                </woot-button>
                <woot-button @click="sync_start">
                  {{ $t('Reiniciar') }}
                </woot-button> 
            </div>
          </div>
        </div>
      </section>
     
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from 'dashboard/components/SettingsSection.vue';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    atoken: {
      type: Object,
      default: () => ({}),
    },
  }, 

  data() {
    return {
      values: [],
      options: [],
      show: true,
      isTouched: false,
      section_start: true,
      qrcode: false,
      loading: false,
      progresso: 25,
      interval2: "none",
    };
  },

  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      agentBots: 'agentBots/getBots',
    }),
       
  },
  async mounted(){
    this.loading = true;
    let status = await this.sync_status(); 
    /*if (status == "Download"){
      this.section_start = false;   
      this.section_download = true;
      
    }
    if (status == "Read"){
      this.section_start = false;
      this.section_read = true;
      
    }*/
    this.loading = false;


    
  },
  

  methods: {
    onClose() {
      clearInterval(this.interval2);
      this.$emit('close');
    },
    contador() {
      //contador do qrcode
      this.counting = true;
      this.sec = 20;
      
      this.interval = setInterval(() => {
        this.sec--;

        if (this.sec == 0) {
          clearInterval(this.interval);
          this.counting = false;
          
          this.loading = false;
          this.qrcode = false;
          //this.showAlert("QR Code Expirado!");


        }
      }, 1000);
    },
    async sync_start(){
      //acionado quando clica no botao iniciar
      


      //checar se existe sync dessa inbox em andamento
      //se sim, mostrar progresso
      //se nao, gerar qrcode
      this.section_start = true; 
      this.loading = true;
      this.qrcode = false;
      this.section_read = false;
      this.section_download = false;
      



      try {
        const response = await axios.post(process.env.WOOTIFY_HSYNC_QRCODE+'/QRCode',
          {
            hsync_api: process.env.WOOTIFY_HSYNC_API,
            hsync_adminToken: process.env.WOOTIFY_HSYNC_ADMINTOKEN,
            
            account: this.accountId,

            inbox_identifier: this.inbox.inbox_identifier,
            inbox_id: this.inbox.id,

            atoken: this.atoken,
            utoken: this.user.access_token,
            
            cwhost: process.env.WOOTIFY_CW_HOST,
            //usar o quepasa para pegar nome contato
            qphost: process.env.WOOTIFY_QP_HOST, 
            qptoken: this.inbox.inbox_identifier,

          },
         {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer'
        });
        if (response.data instanceof ArrayBuffer) {
          let base64String = btoa(
            String.fromCharCode.apply(null, new Uint8Array(response.data))
          );
          this.qrcode = 'data:image/png;base64,' + base64String;         
        }
      } catch (error) {
        console.error(error);
      }
      this.contador();
      this.showAlert("QR Code Gerado"); 
      this.loading = false; 
      this.section_start = true;
      this.sync_status();

      
      
      
    },
    async sync_status(){
      // chamar n8n para checar status
      // checar arquivo identifier-download
      // checar arquivo identifier-read
      // caso nao exista nada: qrcode

      try {
        const response = await axios.post(
          process.env.WOOTIFY_HSYNC_QRCODE+'/status',
          {
            hsync_api: process.env.WOOTIFY_HSYNC_API,
            
            account: this.accountId,

            inbox_identifier: this.inbox.inbox_identifier,
            inbox_id: this.inbox.id,

            atoken: this.atoken,
            utoken: this.user.access_token,
            
            cwhost: process.env.WOOTIFY_CW_HOST,
            //usar o quepasa para pegar nome contato
            qphost: process.env.WOOTIFY_QP_HOST, 
            qptoken: this.inbox.inbox_identifier,

          },
         {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data);

        if(this.interval2 == "none")
          this.interval2 = setInterval(() => { 
            this.sync_status();
          }, 2000);
        
        
        
        if(response.data.conectado){
          this.section_start = false;
          this.section_download = true;

          clearInterval(this.interval);
          this.counting = false;     
          this.loading = false;
          this.qrcode = false;

          if (response.data.download.status == "none"){
            this.section_read = false;
            this.section_download = true;
            this.progresso = 11;
            return "qrcode";
          }

        }
          

        if (response.data.download.status == "none"){
          //sem download mostrar qrcode   
          this.section_read = false;
          this.section_download = false;
          return "qrcode"; 
        }

        if(parseInt(response.data.download.status) >= 0  && parseInt(response.data.download.status) < 100){
          //download em andamento
          this.progresso = parseInt(response.data.download.status);
          this.section_start = false;
          this.section_read = false;
          this.section_download = true;
          return "Download";
        }

        if (response.data.download.status == "100"){
          //download acabou... checar READ
          this.section_start = false;
          this.section_read = true;
          this.section_download = true;

          this.progresso = parseInt(response.data.download.status);

          if (response.data.read.status == "none"){
            this.progresso = 11;
            return "Read";
          }      
            

          this.progresso = parseInt(response.data.read.status); 

          /*
          if (this.progresso < 100)
            this.progresso = this.progresso + 1;
          
          if (this.progresso >= 100)
            this.progresso = 11;
          */

          if(parseInt(response.data.read.status) >= 0  && parseInt(response.data.read.status) < 100){
            //read em andamento 
            this.section_start = false;
            this.section_read = true;
            this.section_download = true;
            return "Read";
          }

          if (response.data.read.status == "100"){
            //complete / ja foi feito o processo 1 vez.
            clearInterval(this.interval2);
            this.interval2 = "none";
            this.section_start = false;
            this.section_download = true;
            this.section_read = true;
            return "Read";
          }
          

        }


      } catch (error) {
        console.error(error);
      }

      return "qrcode";
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'https://www.w3schools.com/w3css/4/w3.css';

.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
.multiselect--wrap {
  margin-bottom: var(--space-normal);
  .error-message {
    color: var(--r-400);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
  }
  .invalid {
    ::v-deep {
      .multiselect__tags {
        border: 1px solid var(--r-400);
      }
    }
  }
}
::v-deep {
  .multiselect {
    margin-bottom: 0;
  }
  .multiselect__content-wrapper {
    display: none;
  } 
  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }
}

.event_wrapper {
  select {
    @apply m-0;
  }
  .info-message {
    @apply text-xs text-green-500 dark:text-green-500 text-right;
  }

  @apply mb-6;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 100vh;*/
}

.spinner-qrcode {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top-color: #82d198;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin-qrcode 1s linear infinite;
}

</style>
